.slider-unlock {
    background: linear-gradient(
        180deg,
        rgb(43, 43, 61) 0%,
        rgb(18, 18, 34) 100%
    );
    border-radius: 32px;
    cursor: pointer;
    width: 280px;
    height: 64px;
    transition-property: background, background-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    position: relative;
    display: block;
    z-index: 8;
    margin-top: 16px;
}
.slider-unlock.no-interact{
    cursor:wait;
}
.slider-unlock.active {
    background: linear-gradient(
        180deg,
        rgb(28, 130, 93) 0%,
        rgb(20, 79, 122) 100%
    );
}
.slider-thumb {
    position: relative;
    display: inline-block;
    background: #171740;
    border: 1px solid #1be1f2;
    background-image: url('../../assets/svg/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 64px;
    width: 64px;
    border-radius: 100%;
    z-index: 10;
}
.no-interact .slider-thumb {
    background-image: unset;
}
.slider-thumb.free{
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: 150ms;
}
.display-text.slider-unlock::after {
    position: relative;
    display: inline-block;
    bottom: 25px;
    left: 20px;
    font-size: 20px;
    content: 'slide to unlock';
    z-index: 9;
}
.active .slider-thumb {
    background: linear-gradient(180deg, #2fd89b 0%, #0e92f1 100%);
}
