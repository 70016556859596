@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    height: 100%;
}

.app-container {
    height: 100%;
}
@font-face {
    font-family: 'Slalom Sans';
    src: url('./assets/fonts/SlalomSans-Italic.woff2') format('woff2'),
        url('./assets/fonts/SlalomSans-Light.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Slalom Sans';
    src: url('./assets/fonts/SlalomSans-Black.woff2') format('woff2'),
        url('./assets/fonts/SlalomSans-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Slalom Sans';
    src: url('./assets/fonts/SlalomSans-Light.woff2') format('woff2'),
        url('./assets/fonts/SlalomSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Slalom Sans';
    src: url('./assets/fonts/SlalomSans-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/SlalomSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Slalom Sans';
    src: url('./assets/fonts/SlalomSans-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/SlalomSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Slalom Sans';
    src: url('./assets/fonts/SlalomSans-BlackItalic.woff2') format('woff2'),
        url('./assets/fonts/SlalomSans-BlackItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Slalom Sans';
    src: url('./assets/fonts/SlalomSans-Bold.woff2') format('woff2'),
        url('./assets/fonts/SlalomSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Slalom Sans';
    src: url('./assets/fonts/SlalomSans-Regular.woff2') format('woff2'),
         url('./assets/fonts/SlalomSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}