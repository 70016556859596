.event-container {
    min-height: 150px;
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    position: relative;
    align-items: stretch;
}
.date-container {
    position: relative;
    float: left;
    background: url('../../assets/png/event_date_thumbnail.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(27, 225, 242, 0.67);
    width: 15%;
    min-width: 75px;
    border-radius: 10px 0px 0px 10px;
    box-sizing: border-box;
    align-self: stretch;
}
.past-date {
    background: url('../../assets/png/past_event_date_thumbnail.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #13132E;
}
.info-container {
    float: right;
    background: #13132e;
    border: 1px solid #20203b;
    border-left: 0;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    flex: 2;
}
.past-info {
    background-color: #20203b;
}
.date {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #ffffff;
}
.year {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    margin-bottom: -1px;
}

.day {
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    margin-bottom: -0.5px;
}

.month {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
}

.event-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    flex-shrink: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    padding: 24px;
}

.event-info {
    padding: 0px 24px 24px 24px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.event-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.event-notes-body {
    display: flex;
    flex-direction: row;
}

.event-notes {
    display: flex;
    flex-direction: column;
    justify-self: start;
    overflow: hidden;
    width: 100%;
    flex: 1 1 400px;
    height: auto;
    line-height: 18px;
}

.event-header {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: #1be1f2;
    text-align: left;
}

.button-container {
    width: 100%;
    height: 100%;
}

.read-more {
    height: 100%;
    display: flex;
    align-items: flex-end;
    margin-top: auto;
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1be1f2;
}

.read-more:hover {
    cursor: pointer;
}
.locks-badge {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    justify-self: end;
    align-self: flex-end;
    white-space: nowrap;
    margin-right: 4px;
    place-self: start;
    background: #20203b;
    border-radius: 12px;
    padding: 4px 10px 6px 10px;
}
.past-locks-badge {
    background-color: #13132E;
}

.event-title-content {
    margin-right: 12px;
}

.current-marker {
    display: inline-block;
    margin-right: 4px;
    height: 20px;
    width: 20px;
    background: radial-gradient(
        50% 50% at 50% 50%,
        #1be1f2 44.79%,
        #13132e 100%
    );
}

.badge {
    display: inline-block;
    border: 1px solid #ffffff;
    border-radius: 15px;
    color: #ffffff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    padding: 5px 15px 5px 15px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
}
.badge-error {
    border: 1px solid #ff8888;
    color: #ff8888;
}

@media only screen and (max-width: 1023px) {
    .event-title {
        font-size: 16px;
        line-height: 24px;
    }

    .event-title .locks-badge,
    .event-title .badge {
        display: none;
    }

    .event-info.hide-details .event-sponsor,
    .event-info.hide-details .event-leader,
    .event-info.hide-details .event-notes,
    .event-info.hide-details .event-locks {
        display: none;
    }

}

@media only screen and (max-width: 700px) {

    .year {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: -5px;
    }
    
    .day {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: -5px;
    }
    
    .month {
        font-size: 18px;
        line-height: 21px;
    }

}