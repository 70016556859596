.modal-header {
    width: 100%;
    height: fit-content;
    font-family: 'Slalom Sans';
    font-style: normal;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
}
.modal-title {
    font-size:  24px;
    line-height: 3rem /*48px*/;
    margin-right: 1rem /*16px*/;
}
.modal-body {
    font-family: 'Slalom Sans';
    font-style: normal;
    height: fit-content;
}
.modal-close-button {
    width: 2.8rem /*37px*/;
    height: 2.1rem /*32px*/;
    align-self: center;
    flex-shrink: 0;
}
.modal-close-icon {
    align-self: center;
}
.modal-close-button:hover {
    background-color: rgba(147, 177, 180, 0.25);
    transition: background-color ease-in-out;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.25s;
    box-sizing: border-box;
    border: 1px solid #20203b;
    border-radius: 3px;
}

@media only screen and (max-width: 780px) {
    .modal-title {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
}

@media only screen and (max-width: 520px) {
    .modal-title {
        font-size: 1.25rem;
        line-height: 1.25rem;
            margin-right: .5rem
                    /*16px*/
                ;
    }
}