.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    padding: 0px 96px 0px 96px;
    margin-top: 2.5rem;
}
.button-container {
    height: 48px;
    width: fit-content;
    margin: 50px 96px 20px 96px;
    background-color: #13132e;
    border-radius: 10px;
    position: relative;
    border: 2px solid #2B2B53;
}
.upcoming-lock-event-button,
.past-lock-event-button,
.active-highlight {
    font-size: 20px;
    align-self: center;
    width: 260px;
    height: 100%;
    border-radius: 5px;
    z-index: 9;
    position: relative;
}

.active-highlight {
    top: 0;
    z-index: 8;
    position: absolute;
    background-color: #2b2b53;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.active-highlight.over {
    transform: translateX(260px);
}

.add-event-button {

    box-sizing: border-box;
    margin-left: auto;
    margin: 0;
    padding: 0;
    width: 10.875rem /*174*/;
    height: 3rem /*48*/;
    background: #1be1f2;
    border: 0.1vmin solid #3acad7;
    align-items: center;
    border-radius: 2px;
    color: black;
    font-weight: 700;
    font-size: 0.575rem /*14px*/;
    line-height: 1.5rem;
    margin-top: 1rem;
}
.add-event-button:hover {
    cursor: pointer;
}
.event-page-title {
    position: relative;
    float: left;
    font-weight: 700;
    font-size: 44px;
    line-height: 48px;
    text-align: left;
    color: white;
    flex-shrink: 0;
    flex-basis: fit-content;
}
@media only screen and (max-width: 1024px) {
    .event-page-title {
        font-size: 36px;
        line-height: 38px;
        margin-bottom: 1rem;
        font-size: 34px;
        line-height: 38px;
    }

    .button-container {
        margin: 0px 24px 24px 24px;
    }
    .upcoming-lock-event-button,
    .past-lock-event-button,
    .active-highlight {
        width: 170px;
        font-size: 16px;
    }

    .active-highlight.over {
        transform: translateX(170px);
    }

    .header {
        padding: 0px 24px 24px 24px;
        margin-top: 1rem;
    }

    .add-event-button {
        margin: 0px 24px 0px 0px;
    }
}
@media only screen and (max-width: 400px) {
    .event-page-title {
        margin-bottom: 1.5rem /*24px*/;
        font-size: 20px;
        line-height: 20px;
    }

    .upcoming-lock-event-button,
    .past-lock-event-button,
    .active-highlight {
        width: 140px;
        font-size: 12px;
    }

    .active-highlight.over {
        transform: translateX(140px);
    }
}
@media only screen and (max-width: 300px) {
    .header {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .event-page-title {
        margin-bottom: 1.25rem /*20px*/;
    }

}
