@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: 'Slalom Sans';
    font-style: normal;
    font-weight: 300;
    height: 100vh;
    width: 100%;
    background-color: #070717;
}
