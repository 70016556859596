
/* To target the label of the input fields */
.MuiInputLabel-root[data-shrink="true"] {
    background-color: rgb(18 18 45 / var(--tw-bg-opacity));
    padding: 2px;
    transition-property: background-color, transform;
    transition:cubic-bezier();
    border: 2px;
}

.MuiSvgIcon-root{
    padding: 0;
    padding-left: 2px;
}
