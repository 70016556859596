.navlink {
    @apply text-grey;
    transition-property: fill, stroke, color;
    transition-duration: 150ms;
}
.navlink span:after {
    /* Slalom Sans has slightly different character widths by weight, this is a hidden pseudo element
    This holds the span's width to that of the bold text even when the text isn't bold */
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 0px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
}
.navlink.active {
    font-weight: 700;
    @apply text-white;
}
.navlink svg.fill {
    @apply fill-grey;
}
.navlink:hover {
    @apply text-white;
}
.navlink:hover svg.fill {
    @apply fill-white;
}
.navlink:hover svg.stroke {
    @apply stroke-white;
}
.navlink.active svg.fill {
    @apply fill-cyan;
}

.navlink svg.stroke {
    @apply stroke-grey;
}
.navlink.active svg.stroke {
    @apply stroke-cyan;
}
.mobile.navlink:hover {
    background: rgba(217, 217, 217, 0.17);
}
.offcanvas-gradient {
    background: linear-gradient(180deg, #171740 0%, #0a0a1d 100%);
}
#bca_title {
    font-family: 'Slalom Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
}
