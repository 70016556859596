.spinner-circle{
    fill: transparent;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-dasharray: 62.8;
    transform-origin: 50% 50%;
    animation: spinner 3s linear infinite;
}
@keyframes spinner {
    0% {
        stroke-dashoffset: 13.2;
        transform: rotate(0deg);
    } 50% {
        stroke-dashoffset: 62.8;
        transform: rotate(720deg);
    } 100% {
        stroke-dashoffset: 13.2;
        transform: rotate(1080deg);
    }
}