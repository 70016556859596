.mobile-list {
    display: none;
}

.grid-wrap {
    display: grid;
    grid-template-columns: minmax(max-content, 1fr) 2fr minmax(max-content, 1fr) 2fr max-content;
    row-gap: 0.5rem;
}

.header {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    text-decoration-line: underline;
    color: #1be1f2;
    margin: 1rem 0.5rem 0.5rem 1rem;
    display: flex;
    cursor: pointer;
}
.header>img {
    margin-left: 0.5rem;
}

.item-row {
    display: contents;
    align-items: center;
}

.item {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    background: #12122d;
    width: 100%;
    height: 100%;
    min-height: 2rem;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions {
    background: #12122d;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
}
.actions > button {
    padding: 0.5rem;
}

.page-control {
    margin: 2rem 0 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-selector {
    display: flex;
    height: 1.5rem;
    width: 1.5rem;
    justify-content: center;
    align-items: center;
    margin: 0 0.25rem 0 0.25rem;
    background-color: #12122d;
}

.page-selector.active {
    background-color: #2b2b53;
}

.page-arrow {
    display: flex;
    height: 1.5rem;
    width: 1.5rem;
    justify-content: center;
    align-items: center;
    margin: 0 0.25rem 0 0.25rem;
}

@media only screen and (max-width: 1023px) {
    .grid-wrap {
        display: none;
    }
    .mobile-list {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        gap: 1rem;
    }
    .header {
        margin: 0;
        text-decoration-line: none;
    }
}

.mobile-item {
    display: grid;
    grid-template-columns: minmax(max-content, 1fr) 1.5fr;
    grid-template-rows: repeat(4, min-content);
    border-radius: 0.75rem;
    border-left-width: 0.75rem;
    border-color: #189db1;
    background-color: #12122d;
    padding: 1rem 0.5rem 1rem 1rem;
    gap: 1rem 0.25rem;
    scroll-snap-align: start;
    min-width: 100%;
    z-index: 10;
}
.mobile-item-contents {
    line-height: 1rem;
}
.swipe-container {
    display: flex;
    overflow: auto;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    position: relative;
}
.swipe-dummy {
    display: block;
    width: 16rem;
    scroll-snap-align: end;
    height: 1px;
    flex-shrink: 0;
}
.swipe-container::-webkit-scrollbar {
    display: none;
}
.hide-container {
    position: relative;
}
.swipe-actions {
    scroll-snap-align: end;
    display: flex;
    position: absolute;
    height: 100%;
    right: 0;
    top:0;
    z-index: 9;
}
.edit-action,
.delete-action {
    height: 100%;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-action{
    background-color: #20203B;
    width: 9rem;
    padding-left: 1rem;
}
.delete-action {
    background-color: #9C3D3D;
    border-radius: 0 0.75rem 0.75rem 0;
}
