.list-container {
    height: 100%;
    margin: 0px 96px 0px 96px;
}
.event-list-title {
    margin: 0;
    width: fit-content;
    font-size: 22px;
    margin-left: 96px;
    margin-bottom: 8px;
    line-height: 24px;
    position: relative;
    float: left;
}
.modal-body {
    width: 46.875rem /*750px*/;
    margin-top: 4rem /*64px*/;
}
.event-header {
    text-transform: uppercase;
    color: #1be1f2;
    text-align: left;
    font-weight: 400;
    font-size: 0.75rem /*12px*/;
    line-height: 1rem /*16px*/;
    letter-spacing: 0.156rem /*2.5px*/;
    margin: 0;
}
.modal-notes {
    color: #ffffff;
    text-align: left;
    font-weight: 400;
    font-size: 1rem /*16px*/;
    line-height: 1.5rem /*24px*/;
    margin-top: 0.5rem /*8px*/;
}

@media only screen and (max-width: 1023px) {
    .event-list-title {
        margin: 0 1.5rem 0 1.5rem;
    }
    .modal-body {
        width: 30.75rem /*380px*/;
        margin-top: 1.938rem /*31px*/;
    }
    .list-container {
        height: 100%;
        padding: 0 1.5rem 0 1.5rem;
        margin: 0;
    }
    .event-list-title {
        margin: 0 0 10px 24px;
    }
}
@media only screen and (max-width: 520px) {
    .modal-body {
        width: fit-content;
        margin-top: 1.938rem /*31px*/;
    }
    .event-list-title-container {
        display: none;
    }
        .event-list-title {
            margin: 0 0 24px 0;
        }
}
